<template>
    <div class="bk-service-area section-ptb-xl bg_image--2 test-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center wow move-up">
                        <h3>WHAT WE DO</h3>
                        <h2>We use our thirty plus years of experience in the Audio-Visual production industry to help you make connections with your audience.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import data from '../../data/service.json';

    import ServiceItemOne from '../ServiceItemOne';

    export default {
        components: {
            ServiceItemOne,
        },

        data () {
            return {
                data
            }
        },
    };
</script>
